import Head from 'next/head';

const SeoHead = ({
  title = 'Link',
  description = 'Link Rewards Programme: Shop, Earn, Redeem & Save More with Linkpoints',
  image = `${process.env.NEXT_PUBLIC_SITE_URL}/static/img/common/og-image.png`,
  noindex,
  children,
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      {noindex && <meta name="robots" content="noindex" />}
      {children}
    </Head>
  );
};
export default SeoHead;
