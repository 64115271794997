import styled from 'styled-components';

import { theme } from '../GlobalStyles';

const Slider = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  small {
    font-size: 0.75rem;
  }

  ${theme.mediaQueries.above.large} {
    flex-direction: row;
    .carousel {
      width: 100%;
      text-align: center;
    }
    small {
      font-size: 0.875rem;
    }
  }
`;

export default Slider;
