import Link from 'next/link';
import styled from 'styled-components';

import { theme } from './GlobalStyles';
import { Box, Contain } from './Grid';
import SeoHead from './SeoHead';

const statusCodes = {
  400: 'Bad Request',
  404: 'This page could not be found',
  405: 'Method Not Allowed',
  500: 'Internal Server Error',
};

const ErrorBox = styled(Box)`
  background-image: linear-gradient(to bottom, #ffffff -4%, #ede5f9 65%);
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: -3.75rem; /*  to overlap Footer padding-top */
  p:last-child,
  h1 {
    padding: 0rem 1.25rem 5rem;
  }
`;

const ErrorText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: ${theme.colors.purple};
    padding: 0 2rem 0 0;
    margin-right: 2rem;
    border-right: 1px solid ${theme.colors.purpleLight};
  }
`;

const ErrorPage = ({
  error = {},
  statusCode,
  code = error.code || statusCode,
  title = error.title,
}) => {
  const errorMsg = title || statusCodes[code] || 'An unexpected error has occurred';

  return (
    <ErrorBox>
      <Contain className="text-center">
        <SeoHead
          title="Oops! This page may have expired or does not exist | Link Rewards Programme"
          description="The page you are trying to access does not exist. You may wish to double-check the URL. Alternatively, contact us for support."
        />
        <img alt="Error" src="/static/img/error.png" height="85%" width="85%" />
        {code === 404 ? (
          <>
            <p>This page may have expired or doesn’t exist.</p>
            <p>
              Hang tight for a second as we connect you through to{' '}
              <Link href="/all-promotions">view the latest promotions!</Link>
            </p>
          </>
        ) : (
          <ErrorText>
            <h1 data-testid="error-code">{code && `${code}`}</h1>
            <h2 data-testid="error-msg" className="h3">
              {errorMsg}
            </h2>
          </ErrorText>
        )}
      </Contain>
    </ErrorBox>
  );
};

export default ErrorPage;
