import ErrorPage from '../components/ErrorPage';

const CustomError = (props) => <ErrorPage {...props} />;

CustomError.getInitialProps = ({ res, err }) => {
  if (res) {
    return { statusCode: res.statusCode };
  }
  if (err) {
    return { statusCode: err.statusCode };
  }
  return { statusCode: 404 };
};

export default CustomError;
