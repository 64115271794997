import React from 'react';
import NextImage from 'next/image';

import cloudfrontLoader from '../utils/cloudfrontLoader';

const Image = ({ alt, ...rest }) => {
  return <NextImage alt={alt} {...rest} loader={cloudfrontLoader} />;
};

export default Image;
