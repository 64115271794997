import Slider from 'react-slick';
import styled from 'styled-components';

import { theme } from '../GlobalStyles';

const StyledSlider = styled(Slider)`
  /* Basic button and anchor styles to remove mobile click highlights */
  button,
  a {
    -webkit-tap-highlight-color: transparent; /* Removes the blue highlight on tap */
    outline: none;
    display: inline-block !important;
  }

  position: relative;

  /* Button styles for carousel navigation */
  .btn {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease-in-out;
    bottom: -9.6875rem;
  }

  ${theme.mediaQueries.above.large} {
    .btn {
      bottom: -7.6875rem;
    }
  }

  .btn-prev {
    position: absolute;
    right: 53%;
    z-index: 1;
  }

  .btn-next {
    position: absolute;
    left: 53%;
  }

  /* Hover effect on navigation buttons */
  .btn-next:hover svg,
  .btn-prev:hover svg {
    width: 3.75rem;
    height: 3.75rem;
  }

  .btn-next:hover svg path:first-child,
  .btn-prev:hover svg path:first-child {
    fill: #73308a;
    stroke: none;
  }

  .btn-next:hover svg path:last-child,
  .btn-prev:hover svg path:last-child {
    fill: #ffffff;
  }

  /* Slick slider structure */
  .slick-list,
  .slick-slider {
    position: relative;
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Slick slide layout and padding adjustments */
  .slick-slide {
    padding: 1rem 0 1rem 0.75rem;
  }

  .slick-slide:first-child {
    padding: 1rem 0 1rem 0.75rem;
  }

  .slick-slide:last-child {
    padding: 1rem 0.75rem 1rem 0;
  }

  /* Middle slides */
  .slick-slide:not(:first-child):not(:last-child) {
    padding: 1rem 0.625rem;
  }

  ${theme.mediaQueries.above.small} {
    .slick-slide {
      padding: 1.5rem;
    }

    .slick-slide:first-child {
      padding: 1.5rem 0 1.5rem 1.5rem;
    }

    .slick-slide:last-child {
      padding: 1.5rem 1.5rem 1.5rem 0;
    }

    .slick-slide:not(:first-child):not(:last-child) {
      padding: 1.5rem;
    }
  }

  .slick-slide {
    height: 100%;
    min-height: 0.0625rem;

    > div {
      display: flex;
    }

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  /* Styling for the slide row container */
  .slider-row {
    background: #ffffff;
    box-shadow: 0 0 2.5rem rgba(115, 48, 138, 0.25);
    border-radius: 2.5rem;
    margin: 0.5rem 0;
    opacity: 0.5;
    padding: 1.875rem 0;
    flex-direction: column-reverse;
    min-height: 43.75rem;
    justify-content: flex-end;

    ${theme.mediaQueries.above.large} {
      flex-direction: row-reverse;
      padding: 2rem 0;
      min-height: 33rem;
    }
  }

  /* Active slide styling */
  .slick-active {
    z-index: 1;
  }

  .slick-active.slick-current .slider-row {
    opacity: 1;
  }

  /* Additional text and layout styles */
  p {
    color: ${theme.colors.black};
  }

  img,
  a {
    display: block;
  }
`;

export default StyledSlider;
