/* eslint-disable import/no-anonymous-default-export */
import styled from 'styled-components';

import Button from '../Button';
import { theme } from '../GlobalStyles';
import { Box, Col, Contain, Row } from '../Grid';

const SC = {};

SC.StyledButton = styled(Button)`
  padding: 0.75rem 1.125rem;
  border-radius: 1.5rem;
  width: 100%;
  ${(props) => props.margin && 'margin-top: 0.5rem;'}

  ${theme.mediaQueries.above.small} {
    width: auto;
  }
  ${theme.mediaQueries.above.large} {
    ${(props) => props.margin && ' margin-left: 0.5rem;'}
  }
`;
SC.StyledBox = styled(Box)`
  background: #ffb5e5;
  padding-bottom: 4rem;
  margin: 4rem 0 -3.75rem;
`;

SC.StyledContain = styled(Contain)`
  h2 {
    color: ${theme.colors.purple};
    font-family: 'Overpass';
    margin-bottom: 0;
  }
  p {
    color: #333333;
  }
`;

SC.StyledRow = styled(Row)`
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  ${theme.mediaQueries.above.large} {
    flex-direction: row-reverse;
  }
`;

SC.StyledImgCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  img {
    min-width: 5.9375rem;
    margin: auto;
  }
  .always-more-img {
    width: 7.5rem !important;
    padding-bottom: 1rem;
  }
  small {
    color: ${theme.colors.purple} !important;
    font-weight: 900;
  }
  ${theme.mediaQueries.above.small} {
    img {
      max-width: 10rem;
    }
    .always-more-img {
      width: 10rem !important;
    }
  }
  ${theme.mediaQueries.above.large} {
    align-self: flex-start;
    padding-left: 4.375rem;
    padding-top: 3rem;
  }
`;

SC.StyledTextCol = styled(Col)`
  align-self: flex-start;
  text-align: left;
  padding-left: 2rem;
  a {
    display: inline-block;
  }
  ol {
    padding: 0 1rem;
    li {
      padding-bottom: 0.75rem;
    }
  }
  ${theme.mediaQueries.above.large} {
    width: 70%;
    padding: 0 1.5rem;
    ol {
      padding: 0 1.5rem;
    }
  }
`;

SC.LearnMoreButton = styled(Button)`
  display: inline-block;
  padding: 0.75rem 1.125rem;
  border-radius: 1.5rem;
  width: 100%;
  a {
    display: inline-block;
  }
  color: ${theme.colors.purple};
  &.is-active,
  &:hover {
    background: ${theme.colors.purple};
    color: #ffffff;
  }
  ${theme.mediaQueries.above.small} {
    width: auto;
    font-size: 1.25rem;
  }
`;

SC.TrustCardBox = styled(Box)`
  background-color: #e5d4e9;
  margin: 7rem auto 0;
  text-align: center;
  padding-bottom: 5.625rem;
  padding-top: 3.75rem;
  a {
    background: #009ddc;
  }
`;

SC.SliderSection = styled.div`
  h1,
  h2 {
    color: ${theme.colors.purple};
  }
  span {
    font-weight: 400;
  }
`;

const homeElements = {
  ...SC,
  Contain,
  Box,
  Row,
  Col,
};

export default homeElements;
