import React from 'react';

// Arrow button components for navigation
const MainCirclePath = () => (
  <path
    d="M0.5 30C0.5 13.734 13.734 0.5 30 0.5C46.266 0.5 59.5 13.734 59.5 30C59.5 46.266 46.266 59.5 30 59.5C13.734 59.5 0.5 46.266 0.5 30Z"
    fill="white"
    stroke="#73308A"
  />
);

const ArrowPath = ({ direction }) => {
  const nextPathD = [
    'M39.2673 31.7672L26.7669 44.2676C26.2794 44.7551 25.6396 45 24.9997 45',
    'C24.3599 45 23.72 44.7551 23.2325 44.2676C22.2552 43.2902 22.2552 41.7105',
    '23.2325 40.732L33.9645 30L23.2325 19.268C22.2552 18.2906 22.2552 16.7109',
    '23.2325 15.7336C24.2099 14.7562 25.7896 14.7562 26.7669 15.7336L39.2673 28.234',
    'C40.2446 29.2113 40.2446 30.791 39.2673 31.7695V31.7672Z',
  ].join(' ');

  const prevPathD = [
    'M20.7327 28.2328L33.2331 15.7324C33.7206 15.2449 34.3605 15 35.0003 15',
    'C35.6401 15 36.28 15.2449 36.7675 15.7324C37.7448 16.7098 37.7448 18.2895',
    '36.7675 19.268L26.0355 30L36.7675 40.732C37.7448 41.7094 37.7448 43.2891',
    '36.7675 44.2664C35.7901 45.2438 34.2104 45.2438 33.2331 44.2664L20.7327 31.766',
    'C19.7554 30.7887 19.7554 29.209 20.7327 28.2305L20.7327 28.2328Z',
  ].join(' ');

  const pathD = direction === 'next' ? nextPathD : prevPathD;

  return <path d={pathD} fill="#73308A" />;
};

export const NavButton = ({ direction, onClick, styles }) => {
  const clipPathId = `clip0_2281_${direction === 'next' ? '489' : '486'}`;
  const transformValue = direction === 'prev' ? 'translate(60 60) rotate(-180)' : '';

  return (
    <button
      aria-label={direction}
      onClick={onClick}
      type="button"
      className={`btn btn-${direction}`}
      style={styles}
    >
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath={`url(#${clipPathId})`}>
          <MainCirclePath />
          <ArrowPath direction={direction} />
        </g>
        <defs>
          <clipPath id={clipPathId}>
            <rect width="60" height="60" fill="white" transform={transformValue} />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};
