import React, { Fragment } from 'react';

import SC from './homeElements';
import SlidersSection from './SliderSection';
import TrustCardSection from './TrustCardSection';

const Home = () => {
  return (
    <Fragment>
      <SC.Contain noPadding>
        <img src="/static/img/pages/home/banner.gif" alt="Banner" />
      </SC.Contain>
      <SC.Contain noPadding>
        <SlidersSection />
      </SC.Contain>
      <TrustCardSection />
    </Fragment>
  );
};

export default Home;
